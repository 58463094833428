import React, { useState } from 'react';
import { Flex, Box } from 'reflexbox';
import styled from '@emotion/styled';

import Button from 'components/Button';
import Icon from 'components/Icon';

import { getThemeColor } from 'utils/css-utils';

const ApiUrl = `https://nwlyudocnvkivwsvuelj.nhost.run/v1/functions/mail/public-newsletter-signup`;

const InputField = styled.input`
  width: 100%;
  border-radius: 25px;
  padding: 12px 20px;
  outline: none;
  background: none;
  box-shadow: none;
  border: 1px solid white;
  color: white;
  margin-right: 10px;

  &::placeholder {
    color: ${getThemeColor('textLight')};
  }
`;

const FormWrapper = styled(Box)`
  max-width: 460px;
  margin: 0 auto;
`;

const Confirmation = styled(Box)`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    margin-right: 10px;
  }
`;

function NewsletterSignup() {
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleInput = (evt) => setEmail(evt.target.value);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (email.length < 1) {
      return;
    }

    setLoading(true);

    const subscribe = async () => {
      try {
        const response = await fetch(ApiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        const json = await response.json();

        setResponse(json);
      } catch (err) {
        setResponse({ error: true });
      }
      setLoading(false);
    };

    subscribe();
  };

  const isError =
    response && response.error && response.error !== 'E-mail already exists.';
  const isSuccessful = response && (response.message || !isError);
  const buttonLabel = isLoading ? 'loading...' : 'Send';

  if (isSuccessful) {
    return (
      <Confirmation type="success">
        <Icon
          name="arrow_right"
          width="20px"
          colorizeStroke
          strokeColor="silverLighten60"
        />
        <span>
          Thanks for your interest in datablocks. Please check your inbox and
          spam folder.
        </span>
      </Confirmation>
    );
  }

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit}>
        <Flex>
          <InputField
            value={email}
            onChange={handleInput}
            placeholder="Your email"
            type="email"
            minLength={1}
          />
          <Button
            style={{ width: 150 }}
            disabled={isLoading}
            type="big"
            color="red"
          >
            {buttonLabel}
          </Button>
        </Flex>
      </form>
      {isError && (
        <Confirmation type="error">
          <span>
            Something went wrong. You can try to contact us
            <a href="https://webkid.io/contact"> here</a>.
          </span>
        </Confirmation>
      )}
    </FormWrapper>
  );
}

export default NewsletterSignup;
