import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { Flex, Box } from 'reflexbox';

import Page from 'components/Page';
import Button from 'components/Button';
import ContentSection from 'components/ContentSection';
import CenterContent from 'components/CenterContent';
import SectionIntro from 'components/SectionIntro';
import { H1, H4, Text } from 'components/Typo';
import NewsletterSignup from 'components/NewsletterSignup';
import Teaser from 'components/Teaser';
import { baseColors } from 'themes';
import { getThemeColor, device } from 'utils/css-utils';

const metaTags = {
  title: 'datablocks',
  description:
    'node-based editor for exploring, analyzing and transforming data without code',
  siteUrl: 'https://datablocks.pro',
  robots: 'index,follow',
};

const HeroWrapper = styled(Flex)`
  position: relative;
  align-items: center;
  flex-wrap: wrap;

  @media ${device.tablet} {
    padding: 30px 0 60px 0;
  }
`;

const SectionSubtitle = styled(H4)`
  font-weight: 400;
  line-height: 1.5;
  margin: 30px 0;
  color: ${getThemeColor('silverDarken30')};
`;

const HeadlineWrapper = styled(Box)`
  width: 100%;
  order: 1;

  ${H1} {
    margin-top: 0;
  }

  @media ${device.tablet} {
    width: 40%;
    order: 0;
    padding-right: 20px;
  }
`;

const GettingStartedWrapper = styled(Box)`
  max-width: 800px;
  margin: 0 auto;
`;

const TeaserImageWrapper = styled(Box)`
  position: relative;
  width: 100%;
  order: 0;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  margin: 20px 0 30px 0;

  @media ${device.tablet} {
    flex: 1;
    order: 1;
    width: 60%;
    margin: 0;
  }
`;

const TeaserImage = styled(StaticImage)`
  width: 100%;
  display: block;
`;

const ContactButtons = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  margin: 0 auto;
  flex-direction: column;
  margin-top: -10px;

  > * {
    flex: 1;
    margin: 15px 0;
  }

  @media ${device.tablet} {
    flex-direction: row;
    max-width: 500px;

    > * {
      margin: 0 10px;
      flex: 1;
    }
  }
`;

const Home = ({ data }) => {
  return (
    <Page theme="dark" metaTags={metaTags}>
      <CenterContent maxWidth="1350px">
        <HeroWrapper>
          <HeadlineWrapper>
            <H1>Wire your data with datablocks</H1>
            <SectionSubtitle>
              a node-based editor for exploring, analyzing and transforming data
              without code
            </SectionSubtitle>
          </HeadlineWrapper>
          <TeaserImageWrapper>
            <StaticImage
              src="../assets/images/datablocks.png"
              placeholder="blurred"
              layout="constrained"
            />
          </TeaserImageWrapper>
        </HeroWrapper>
      </CenterContent>

      <ContentSection id="signup" bg="violetLighten5">
        <CenterContent>
          <SectionIntro
            title="Get the Latest Updates"
            text="Enter your email to get all the updates about datablocks."
            color={baseColors.textLight}
          />
          <GettingStartedWrapper>
            <NewsletterSignup />
          </GettingStartedWrapper>
          <Text
            mt={4}
            style={{ textAlign: 'center', color: baseColors.silverDarken15 }}
          >
            To stay up to date you can also follow us on twitter{' '}
            <a href="https://twitter.com/webk1d" target="_blank">
              @webk1d
            </a>
          </Text>
        </CenterContent>
      </ContentSection>

      <ContentSection centered>
        <SectionIntro title="Feature Overview" color={baseColors.textLight} />
        {data.showcases.edges.map(({ node }, i) => (
          <Teaser
            key={node.title}
            title={node.title}
            description={node.subtitle}
            image={node.image.childImageSharp.gatsbyImageData}
            textPosition={i % 2 === 0 ? 'left' : 'right'}
          />
        ))}
      </ContentSection>

      <ContentSection bg="violetLighten5">
        <CenterContent>
          <SectionIntro
            title="Contact"
            text="If you have any questions, please feel free to contact us."
          />

          <ContactButtons>
            <Button
              as="a"
              href="https://webkid.io/contact"
              icon="mail"
              type="big"
              color="red"
            >
              Contact us
            </Button>
          </ContactButtons>

          <Box mt={5} css={{ textAlign: 'center' }}>
            datablocks is based on our open source library{' '}
            <a href="https://reactflow.dev">react-flow</a>
          </Box>
        </CenterContent>
      </ContentSection>
    </Page>
  );
};

export const pageQuery = graphql`
  query {
    showcases: allShowcasesJson {
      edges {
        node {
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default Home;
