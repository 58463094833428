import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { Flex, Box } from 'reflexbox';

import { H2, Text } from 'components/Typo';
import { device, getThemeSpacePx } from 'utils/css-utils';

const Wrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const ReactFlowWrapper = styled(Box)`
  order: 2;
  margin: ${getThemeSpacePx(4)} ${getThemeSpacePx(0)};

  @media ${device.tablet} {
    order: ${(p) => p.order};
  }

  @media ${device.mobile} {
    margin-top: ${getThemeSpacePx(3)};
  }
`;

const DescriptionWrapper = styled(Box)`
  order: 1;
  margin-bottom: ${getThemeSpacePx(3)};

  @media ${device.tablet} {
    order: ${(p) => p.order};
  }

  @media ${device.mobile} {
    margin-bottom: ${getThemeSpacePx(0)};
  }
`;

const Description = ({ title, description }) => (
  <DescriptionWrapper width={[1, 1, 0.35]}>
    <H2>{title}</H2>
    <Text style={{ opacity: 0.7 }}>{description}</Text>
  </DescriptionWrapper>
);

const Teaser = ({
  title,
  description,
  textPosition = 'left',
  isDark = false,
  image,
}) => {
  const reactFlowOrder = textPosition === 'left' ? 2 : 1;

  return (
    <Wrapper mb={[6, 6, 7]}>
      {textPosition === 'left' && (
        <Description order={1} title={title} description={description} />
      )}
      <ReactFlowWrapper
        width={[1, 1, 0.6]}
        isDark={isDark}
        order={reactFlowOrder}
      >
        <GatsbyImage image={image} />
      </ReactFlowWrapper>
      {textPosition !== 'left' && (
        <Description order={2} title={title} description={description} />
      )}
    </Wrapper>
  );
};

export default Teaser;
